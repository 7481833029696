.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project-container {
    align-items: center;
    display: flex;
}
.project-container-reverse {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
}
.project-container img {
    margin-left: 50px;
    margin-bottom: 30px;
    width: 600px;
    height: 350px;
    opacity: 0.9;
}

.project-container-reverse img {
    margin-right: 50px;
    margin-bottom: 30px;
    width: 600px;
    height: 350px;
    opacity: 0.9;
}

.project-container-reverse .project-description {
    border-radius: 20px;
    position: relative;
    z-index: 1;
    color: #ccd6f6;
    right: -80px;
    background-color: #233554;
    margin: 30px;
}

.project-container .project-description {
    border-radius: 20px;
    position: relative;
    z-index: 1;
    color: #ccd6f6;
    left: -80px;
    background-color: #233554;
    margin: 10px;
}

.project-description h2 {
    margin-top: 10px;
    text-align: center;
}

.project-description p {
    padding: 5px 30px;
}

.project-description ol {
    list-style: none;
    margin: 10px 30px;
}

.project-description li {
    display: inline;
    padding: 5px;
}

.project-description a {
    color: #ccd6f6;
    margin-left: 30px;
    text-decoration: none;
}

.project-description a:hover {
    text-decoration: underline;
}

.footer {
    margin-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
    .project-container img {
        display: none;
    }

    .project-container-reverse img {
        display: none;
    }

    .project-container .project-description {
        left: 0;
    }

    .project-container-reverse .project-description {
        right: 0;
    }

}